// Footer.js
import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>Check this</h4>
                    <ul>
                        <li><a href="#about">About</a></li>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#vision">Vision</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Get in Touch</h4>
                    <address>
                        AlSalehya Center for Computers & Telecom<br />
                        King Fahd Road, 5212<br />
                        Riyadh, Saudi Arabia
                    </address>
                    
                </div>
            </div>
            <p className="footer-copyright">
                &copy; {new Date().getFullYear()} Layan Innovation. All rights reserved.
            </p>
        </footer>
    );
}

export default Footer;
