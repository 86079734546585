import React from 'react';
import Logo from './images/logo1.jpg';

function Navbar() {
    return (
        <div className="navbar">
            <div className="logo">
                <img src={Logo} alt="Layan Innovation" />
            </div>
            <ul className="nav-links">
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#vision">Vision</a></li> 
                <li><a href="#contact">Contact</a></li> 
            </ul>
        </div>
    );
}

export default Navbar;
