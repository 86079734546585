import React, { useState } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Navbar from './Navbar';
import Footer from './Footer';
import './App.css';
import HomeImage from './images/Picture1.jpg';  
import AboutImage from './images/Picture5.jpg'; 
import CardImage1 from './images/Picture6.jpg';
import CardImage2 from './images/Picture7.jpg';
import CardImage3 from './images/Picture9.jpg';
import CardImage4 from './images/Picture10.jpg';
import CardImage5 from './images/Picture11.jpg';
import CardImage6 from './images/Picture12.jpg';
import ContactImage from './images/Picture12.jpg';

function App() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const { name, email, message } = formData;
    
        // Create the mailto link
        const mailtoLink = `mailto:info@layan-innovation.com?subject=Contact Form Submission from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
        
        // Open the mail client with the mailto link
        window.location.href = mailtoLink;
    
        // Immediately reset the form fields for visual feedback
        setTimeout(() => {
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        }, 3000); // 3 sec delay
    };
    


    return (
        <ParallaxProvider>
            <Navbar />
            {/* Home */}
            <Parallax speed={-10}>
                <section
                    id="home"
                    className="home"
                    style={{
                        backgroundImage: `url(${HomeImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        color: 'white',
                        textAlign: 'center',
                        padding: '100px 20px'
                    }}
                >
                    <h1>Optimize Your IT Solutions</h1>
                    <p>Elevate your IT prowess – let Layan Innovation streamline your technological journey to success.</p>
                    <button>Contact Us</button>
                </section>
            </Parallax>

            {/* About Section */}
            <Parallax speed={-5}>
                <section id="about" className="about">
                    <div className="about-content">
                        <div className="about-text">
                            <h2>Innovative IT Service Provider</h2>
                            <p>
                                Layan Innovation is a pioneering IT hardware service provider firm based in Riyadh, Saudi Arabia. Our expert team is dedicated to delivering cutting-edge solutions that drive business transformation and efficiency. We specialize in harnessing the latest technologies to provide tailored consulting services that meet the unique needs of our clients. Whether you are looking to optimize your operations or implement new digital strategies, Layan Innovation is your trusted partner for success.
                            </p>
                        </div>
                        <div className="about-image">
                            <img src={AboutImage} alt="About Layan Innovation" />
                        </div>
                    </div>
                </section>
            </Parallax>

            {/* Services Section */}
            <Parallax speed={-5}>
                <section id="services" className="services">
                    <div className="services-content">
                        <div className="services-text">
                            <h5>Effortless Expertise</h5>
                            <h3>Seamless Solutions for Business</h3>
                            <p>
                                Unlock with our tech-savvy solutions, seamlessly integrating innovation and excellence into your business strategy.
                            </p>
                        </div>
                        <div className="service-gallery">
                            <div className="service-card">
                                <img src={CardImage1} alt="Scalable Infrastructure" />
                                <h4>Scalable Infrastructure</h4>
                                <p>Easily expand your IT resources as your business grows.</p>
                            </div>
                            <div className="service-card">
                                <img src={CardImage2} alt="Cost Efficiency" />
                                <h4>Cost Efficiency</h4>
                                <p>Reduce operational costs with our pay-as-you-go pricing model.</p>
                            </div>
                            <div className="service-card">
                                <img src={CardImage3} alt="Discover Top Brand Products" />
                                <h4>Discover Top Brand Products</h4>
                                <p>We bring you cutting-edge technology solutions to meet your diverse needs and elevate your computing experience.</p>
                            </div>
                            <div className="service-card">
                                <img src={CardImage4} alt="High Availability" />
                                <h4>High Availability</h4>
                                <p>Ensure you are always up and running with the latest technology with our reliable solutions.</p>
                            </div>
                            <div className="service-card">
                                <img src={CardImage5} alt="Quality Assured" />
                                <h4>Quality Assured</h4>
                                <p>We are dedicated to delivering solutions that meet the highest standards of quality.</p>
                            </div>
                            <div className="service-card">
                                <img src={CardImage6} alt="Flexibility" />
                                <h4>Flexibility</h4>
                                <p>Our expertise and technology empower organizations to provide solutions no matter where they are situated.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>

            {/* Vision Section */}
            <Parallax speed={-5}>
                <section id="vision" className="vision">
                    <div className="vision-content">
                        <p>
                            Discover the future with Layan Innovation, IT solution powerhouse. We decode your unique business challenges and transform them into digital triumphs. With a passion for tech and a commitment to excellence, our team crafts innovative solutions tailored just for you. Elevate your potential; redefine what's possible with us by your side.
                        </p>
                    </div>
                </section>
            </Parallax>

            {/* Contact Section */}
            <Parallax speed={-5}>
                <section id="contact" className="contact">
                    <div className="contact-heading">
                        <h2>Commitment to Your Success</h2>
                        <p>At Layan Innovation, our services are driven by values that reflect our dedication to client success.</p>
                    </div>

                    <div className="contact-values">
                        <div className="contact-value">
                            <h3>Client-Centric</h3>
                            <p>We prioritize understanding and meeting each client’s distinct needs.</p>
                        </div>
                        <div className="contact-value">
                            <h3>Collaboration</h3>
                            <p>We work closely with clients, fostering partnerships that strengthen growth.</p>
                        </div>
                        <div className="contact-value">
                            <h3>Adaptability</h3>
                            <p>We offer flexible solutions to meet the changing demands of the IT sector.</p>
                        </div>
                    </div>

                    <div className="contact-bottom">
                        <form className="contact-form" onSubmit={sendEmail}>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                rows="4"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />

                            <button type="submit">Submit</button>
                        </form>
                        <div className="contact-image">
                            <img src={ContactImage} alt="Contact Us" />
                        </div>
                    </div>
                </section>
            </Parallax>
            <Footer />
        </ParallaxProvider>
    );
}

export default App;
